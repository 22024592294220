import styled, { css } from 'styled-components';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';

export const ItemContainer = styled.div<{ direction?: 'row' | 'column' }>(
    ({ direction }) => css`
        display: flex;
        flex-direction: ${direction || 'row'};
        justify-content: space-between;
    `
);

export const Label = styled.label`
    display: block;

    ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [9, 10, 10], [13, 18, 18])}

    ${fluidSizing([
        { prop: 'margin-bottom', values: [5, 5, 5] },
        { prop: 'margin-left', values: [8, 5, 5] }
    ])}
`;

export const Item = styled.div(
    () => css`
        flex: 1;
    `
);
