import * as Styled from './OxPhoneInput.styled';

import React, { ChangeEvent, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import { ThemedInputContext } from 'src/context/ThemedInputContext';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';

type CountryData = {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
};

type OxPhoneInputProps = {
    name: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    pattern?: string;
    initialValue?: string;
    canEditCountryCode?: boolean;
    initialCountryCode?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: MouseEvent<HTMLInputElement>, data: CountryData | unknown) => void;
    value?: string;
};

export const OxPhoneInput: React.FC<OxPhoneInputProps> = ({
    canEditCountryCode,
    initialCountryCode,
    disabled,
    initialValue,
    name,
    onChange,
    onClick,
    pattern,
    placeholder,
    required,
    value
}) => {
    const { geoIpCountry } = useContext(WebsiteDataContext);
    const ref = useRef();

    /**
     * getCountryData, valueSansDialingCode, and isPopulated all have to be functions
     * Why?  Who knows, but if they get set outside of useEffect they end up being wrong
     */
    const getCountryData = (): CountryData => ref.current?.getCountryData() ?? ({} as CountryData);

    const [val, setVal] = useState<string>(() => initialValue || value || '');
    const valSansDialingCode = (providedVal?: string): string =>
        (providedVal || val)?.replace('+', '').slice(getCountryData().dialCode?.length ?? 0) ?? '';
    const isPopulated = (): boolean => valSansDialingCode().length > 0;

    const [country, setCountry] = useState<string>(() => {
        if (valSansDialingCode().length > 0) return '';

        switch (true) {
            case !!initialCountryCode:
                return initialCountryCode?.toLowerCase() ?? '';
            case canEditCountryCode && geoIpCountry:
                return geoIpCountry?.toLowerCase() ?? '';
            case !!initialValue:
                return '';
            default:
                return 'gb';
        }
    });

    const [touched, setTouched] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const themeContext = useContext(ThemedInputContext);
    const validationMessage = 'Please enter a working telephone number.';

    /**
     * Actually validating the number
     * @param number
     */
    const validateNumber = (number: string | number | undefined): boolean => {
        number = number || '';
        return number.toString().length >= 6;
    };

    /**
     * on input change event, here is where we set the custom validity message
     * @param number
     * @param _country
     * @param e
     * @param _formattedValue
     * @param blockOnChange Block the onChange event from firing
     */
    const onPhoneChange = (
        number: string,
        _country: CountryData,
        e: ChangeEvent<HTMLInputElement>,
        _formattedValue?: string,
        blockOnChange?: boolean
    ): void => {
        blockOnChange = blockOnChange || false;
        const target = e.target as HTMLInputElement;
        target?.setCustomValidity?.('');

        setVal(number);
        if (!validateNumber(number)) {
            setIsValid(false);
            target?.setCustomValidity?.(validationMessage);
        } else {
            setIsValid(true);
        }
        onChange && !blockOnChange && onChange(e);
    };

    /**
     * on valid event - slightly more complex than it should be, because we need to
     * check input's validity on initial load, so here we are checking if the input
     * has already been validated, and if the ref to the input actually exists.
     *
     * If validation hasn't been run before, or when it ran the input ref was undefined,
     * we run the onPhoneChange event which will then set whether the input is valid or not
     *
     * Slightly convoluted, but couldn't see a better way of doing this because of how
     * the PhoneInput's events are set up
     * @param number
     * @param _country
     */
    const onIsValid = (number: string | number | undefined, _country: CountryData): boolean => {
        if (isValid === null && ref && ref.current) {
            const current = ref.current as unknown as {
                numberInputRef: HTMLInputElement;
            };

            const input = current?.numberInputRef;
            const payload = { target: input } as ChangeEvent<HTMLInputElement>;
            onPhoneChange(input?.value, getCountryData(), payload, input.value);
        }
        return !touched || validateNumber(number);
    };

    /**
     * If no value exists, we want to change the country
     */
    useEffect(() => {
        if (!isPopulated() && initialCountryCode) {
            setCountry(initialCountryCode.toLowerCase());
        }
    }, [initialCountryCode]);

    /**
     * For some reason simply passing through the country prop doesn't work
     * so we update manually.  Could probably be merged with the above useEffect
     */
    useEffect(() => {
        country && ref.current?.updateCountry(country);
    }, [country]);

    /**
     * When state controlled value changes, we go through the onPhoneChange
     * method for validation
     */
    useEffect(() => {
        const current = ref.current as unknown as {
            numberInputRef: HTMLInputElement;
        };

        const input = current?.numberInputRef;
        const payload = { target: input } as ChangeEvent<HTMLInputElement>;

        typeof value !== 'undefined' &&
            valSansDialingCode(value) &&
            onPhoneChange(value, getCountryData(), payload, value, true);
    }, [value]);

    return (
        <Styled.Container customTheme={themeContext}>
            <PhoneInput
                ref={ref}
                disabled={disabled}
                inputProps={{
                    name: name,
                    required: required,
                    pattern: pattern
                }}
                value={val}
                placeholder={placeholder}
                isValid={onIsValid}
                onChange={onPhoneChange}
                onClick={onClick}
                onBlur={(): void => {
                    setTouched(true);
                }}
                enableLongNumbers={true}
                country={country}
                countryCodeEditable={canEditCountryCode || false}
                containerClass="react-phone-input"
                inputClass="react-phone-input__input"
                buttonClass="react-phone-input__button"
                dropdownClass="react-phone-input__dropdown"
                searchClass="react-phone-input__search"
            />
            {isValid === false && touched && validationMessage && (
                <Styled.Error>{validationMessage}</Styled.Error>
            )}
        </Styled.Container>
    );
};
