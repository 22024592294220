import * as Styled from './OxSelect.styled';

import React, {
    ChangeEventHandler,
    SelectHTMLAttributes,
    useContext,
    useEffect,
    useState
} from 'react';

import { ThemedInputContext } from 'src/context/ThemedInputContext';

type TProps<P> = SelectHTMLAttributes<HTMLSelectElement> & {
    title?: string;
    labelName?: string;
    valueName?: string;
    items: (P & { disabled?: boolean })[];
    initialValue?: P;
    value?: P;
    onValueChange?: (value: P | undefined) => void;
    loading?: boolean;
    disableFireOnValueChangeUntilTouched?: boolean;
};

export function OxSelect<P>({
    title,
    initialValue,
    value,
    items,
    valueName,
    labelName,
    onValueChange,
    className,
    loading,
    disableFireOnValueChangeUntilTouched,
    ...props
}: SCProps<'select', TProps<P>>): JSX.Element {
    const themeContext = useContext(ThemedInputContext);

    if (initialValue && value) throw Error("Can't provide both value and initialValue");

    const getValue = (item?: P): string | undefined => {
        if (typeof item === 'string') return item;
        if (typeof item === 'object') {
            if (!valueName) throw Error('valueName prop is required');
            return `${(item as { [key: string]: unknown })[valueName]}`;
        }
        return undefined;
    };

    const getLabel = (item?: P): string | undefined => {
        if (typeof item === 'string') return item;
        if (typeof item === 'object') {
            if (!labelName) throw Error('labelName prop is required');
            return `${(item as { [key: string]: unknown })[labelName]}`;
        }
        return undefined;
    };

    const [selectedValue, setSelectedValue] = useState<string | undefined>(
        getValue(initialValue) || ''
    );
    const [touched, setTouched] = useState(false);

    // if disableFireOnValueChangeUntilTouched, don't fire onValueChange unless touched
    const fireOnValueChange = (val: string | undefined, touchedOverride = false): void => {
        onValueChange &&
            (!disableFireOnValueChangeUntilTouched || touched || touchedOverride) &&
            onValueChange(items.find((item: P) => `${getValue(item)}` === val));
    };

    const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        !value && setSelectedValue(e.target.value);
        fireOnValueChange(e.target.value, true);
        setTouched(true);
    };

    useEffect(() => {
        fireOnValueChange(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        setSelectedValue(getValue(value));
    }, [value]);

    useEffect(() => {
        initialValue && setSelectedValue(getValue(initialValue));
    }, []);

    return (
        <Styled.Container className={className}>
            <Styled.Select
                customTheme={themeContext}
                value={selectedValue}
                onChange={handleChange}
                touched={touched}
                {...props}
            >
                {!!title && <option value="">{title}</option>}
                {items.map((item: P, index: number) => (
                    <option key={index} value={getValue(item)} disabled={item.disabled}>
                        {getLabel(item)}
                    </option>
                ))}
            </Styled.Select>
            {loading ? (
                <Styled.Spinner contextTheme={themeContext} />
            ) : (
                <Styled.Icon name="arrow-1" customTheme={themeContext} />
            )}
        </Styled.Container>
    );
}
